import * as React from 'react';
import { connect } from 'react-redux';

import classnames from 'classnames';

import router from 'next/router';

import {
  CircularProgress,
  createStyles,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import * as GeolocatorActions from '@actions/GeolocatorActions';
import * as SearchActions from '@actions/SearchActions';
import { IGeoLocatorState, ISearchState, IStore } from '@reducers';

const styles = (theme: Theme) =>
  createStyles({
    search: {
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    searchNormal: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    searchCompact: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1
    },
    suggestions: {
      position: 'absolute',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',

      left: theme.spacing(2),
      right: theme.spacing(2),
      top: theme.spacing(8)
    },
    suggestion: {
      width: '100%'
    },
    span: {
      whiteSpace: 'pre'
    },
    iconButton: {
      padding: 10
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative'
    },
    progress: {
      position: 'absolute',
      top: -4,
      left: -4,
      zIndex: 1
    }
  });

const mapDispatchToProps = {
  getCurrentPosition: GeolocatorActions.getCurrentPosition.request,
  getPlacePredictions: SearchActions.getPlacePredictions.request,
  clearPlacePredictions: SearchActions.clearPlacePredictions
};

interface IState {
  search: ISearchState;
  geolocator: IGeoLocatorState;
}

type IProps = IState &
  WithStyles<typeof styles> &
  typeof mapDispatchToProps & {
    autoSearch: boolean;
    autoFocus: boolean;
    variant: 'normal' | 'compact';
    location?: {
      latitude: number;
      longitude: number;
    };
  };

const SearchAutocomplete: React.FC<IProps> = ({
  getCurrentPosition,
  variant = 'normal',
  autoFocus = true,
  getPlacePredictions,
  clearPlacePredictions,
  classes,
  geolocator,
  search: { autocompletePredictions, isFetching },
  location
}) => {
  React.useEffect(() => {
    getCurrentPosition();
  }, []);

  const [searchQuery, setSearchQuery] = React.useState('');

  const onSubmit = () => {
    if (searchQuery.length > 0) {
      router.push(`/map?query=${searchQuery}`);

      setSearchQuery('');
      clearPlacePredictions();
    }
  };

  return (
    <div
      className={classnames(
        classes.search,
        variant === 'compact' ? classes.searchCompact : classes.searchNormal
      )}
    >
      <InputBase
        id="search-input"
        autoFocus={autoFocus}
        value={searchQuery}
        className={classes.input}
        placeholder="Address, neighborhood, city, ZIP"
        inputProps={{ 'aria-label': 'search lieuu' }}
        onChange={({ target }) => {
          setSearchQuery(target.value);
          getPlacePredictions({
            input: target.value,
            location: location ? location : geolocator && geolocator.location
          });
        }}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            ev.preventDefault();

            onSubmit();
          }
        }}
      />
      {autocompletePredictions.length > 0 && searchQuery.length > 0 && (
        <Paper id="suggestions-paper" className={classes.suggestions}>
          {autocompletePredictions.map((suggestion) => {
            const matches = match(suggestion.description, searchQuery);
            const parts = parse(suggestion.description, matches);

            const href = `/map?query=${suggestion.description}`;

            const onClick = () => {
              router.push(href);
              setSearchQuery('');
              clearPlacePredictions();
            };

            return (
              <MenuItem
                key={suggestion.description}
                onClick={onClick}
                className={classes.suggestion}
                component="div"
              >
                {parts.map(({ highlight, text }, i) => (
                  <span
                    key={i}
                    className={classes.span}
                    style={{ fontWeight: highlight ? 600 : 400 }}
                  >
                    {text}
                  </span>
                ))}
              </MenuItem>
            );
          })}
        </Paper>
      )}
      <div className={classes.wrapper}>
        <IconButton
          className={classes.iconButton}
          aria-label="search"
          disabled={isFetching}
          onClick={onSubmit}
        >
          <SearchIcon />
        </IconButton>
        {isFetching && (
          <CircularProgress
            thickness={2}
            size={50}
            className={classes.progress}
          />
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(
  connect(
    (state: IStore): IState => ({
      search: state.search,
      geolocator: state.geolocator
    }),
    mapDispatchToProps
  )(SearchAutocomplete)
);
